* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  color: #e7e7e7;
  font-family: Inter, "sans-serif";
  letter-spacing: -0.35px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

ul {
  list-style: none;
}

button,
input,
select,
textarea {
  margin: 0;
}

html {
  box-sizing: border-box;
  height: 100vh;
  user-select:none;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

img,
video {
  height: auto;
  max-width: 100%;
}

iframe {
  border: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
  text-align: left;
}

button:focus {
  outline: none;
}

button {
  font-family: inherit;
}

a {
  text-decoration: none;
  color: inherit;
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }
}

body {
  &.modal-open {
    @media (max-width: 768px) {
      height: 100vh;
      overflow: hidden;
    }
  }
}

.no-padding {
  padding: 0;
}

